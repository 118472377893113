import { PaginationProps } from 'antd';
import { createSlice, createAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AdtopicState, ShowRuleModal } from 'src/dashboard/types';
import { getLocalShopAccountName, setLocalShopAccountName } from 'src/utils/adHelper';
import { RuleTypeEnum } from './component/RightContent/AutoRule/RuleDetail/type';
import { getRuleDataList } from './component/RightContent/AutoRule/RulePage/api';
import { getAppRuleList, getFilterData } from './component/RightContent/AutoRule/AppRule/api';
import { AppRuleFilterType, GetAppRuleListParams, RuleItem } from './component/RightContent/AutoRule/AppRule/type';
import { adTypeTargetingTypeMap } from './component/Filters/CommonFilter/config';
import {
  AdGroupFilterType,
  CampaignFilterType,
  NegativeTargetFilterType,
  PortfolioFilterType,
  ProductFilterType,
  SearchTermFilterType,
  TargetFilterType,
} from './component/RightContent/AdAnalysis/type';
import {
  GroupNegativeTargetFilterType, GroupTargetFilterType
} from './component/RightContent/AdGroupDetail/type';

const initialState: AdtopicState = {
  assistantId: new URLSearchParams(location.search).get('id') || 'analysis',
  tab: new URLSearchParams(location.search).get('tab') || '',
  selectShopAccountName: getLocalShopAccountName() || '',
  selectDate: undefined,
  showRuleModal: {
    visible: false,
    type: 'BID',
    editId: undefined,
  },
  dataLoading: false,
  ruleDataSource: [],
  // suggestionOperationData: [],
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  },
  columns: {
    SingleNegativeTarget: ['negative_keyword_text', 'negative_state', 'negative_match_type', 'negative_create_date'],
    AdTarget: ['keyword', 'ad_keyword_status', 'keyword_bid', 'sum_spend', 'amt_ad_14d', 'acos_14d', 'roas_14d', 'sum_impressions', 'sum_clicks', 'ctr', 'cpc', 'cnt_ad_14d', 'cvr_14d'],
    Portfolio: ['cpc','portfolio_name', 'adtype', 'campaign_status', 'sum_spend', 'amt_ad_14d', 'units_sold_14d', 'acos_14d', 'roas_14d', 'sum_impressions', 'sum_clicks', 'ctr', 'cvr_14d', 'cnt_ad_14d'],
    Campaign:['start_date','portfolio_name','campaign_name','campaign_daily_budget','budget_usage_percent','adtype','campaign_status','campaign_daily_budget','sum_spend','amt_ad_14d','units_sold_14d','acos_14d','roas_14d','sum_impressions','sum_campaign_budget_amount','use_budget_rate','sum_clicks','ctr','cpc','sum_viewable_impressions','cnt_ad_14d','cvr_14d'],
    AdGroup: ['portfolio_name', 'campaign_name', 'ad_group_name', 'campaign_status', 'date', 'sum_spend', 'amt_ad_14d', 'units_sold_14d', 'acos_14d', 'roas_14d', 'sum_impressions', 'sum_clicks', 'ctr', 'cpc', 'cvr_14d', 'cnt_ad_14d', 'sum_viewable_impressions'],
    Target: ['keyword_bid','cvr_14d','amt_ad_14d','portfolio_name', 'campaign_name', 'ad_group_name', 'adtype', 'campaign_status', 'ad_group_state', 'match_type', 'keyword_state', 'keyword', 'targeting', 'date', 'sum_spend', 'units_sold_14d', 'acos_14d', 'roas_14d', 'sum_impressions', 'sum_clicks', 'ctr', 'cpc', 'cnt_ad_14d'],
    NegativeTarget: ['amt_ad_14d','marketplace_id','negative_create_date','shop_account_name','ad_type','portfolio_name','portfolio_state','campaign_name','campaign_state','ad_group_name','ad_group_state','campaign_targeting_type','negative_state','negative_match_type','negative_keyword_text'],
    SearchTerm: ['amt_ad_14d','country_name', 'portfolio_name', 'adtype', 'ad_group_name', 'campaign_name', 'campaign_status', 'search_term', 'sum_spend', 'week_search_frequency_num','date', 'units_sold_14d', 'acos_14d', 'roas_14d', 'sum_impressions', 'sum_clicks', 'ctr', 'cpc', 'cvr_14d', 'cnt_ad_14d', 'sum_week_search_frequency_num'],
    Product:['amt_ad_14d','date','portfolio_name','campaign_name','campaign_status','adtype','ad_group_name','ad_status','advertised_asin','item_name','images','sum_spend','units_sold_14d','acos_14d','roas_14d','sum_impressions','sum_clicks','ctr','cpc','cvr_14d','cnt_ad_14d'],
    AiBidding:[ 'keyword', 'campaign_name', 'ad_group_name', 'sum_spend', 'amt_ad_14d', 'acos_14d', 'target_acos', 'modify_time', 'current_keyword_bid', 'cpc', 'target_bid', 'reason', 'sum_impressions', 'ctr', 'cvr', 'cnt_ad_14d', 'sum_clicks', 'action'],
    Bidding:[ 'keyword', 'campaign_name', 'ad_group_name', 'sum_spend', 'amt_ad_14d', 'acos_14d', 'modify_time', 'rule_name', 'current_keyword_bid', 'cpc', 'target_bid', 'reason', 'sum_impressions', 'ctr', 'cvr', 'cnt_ad_14d', 'sum_clicks', 'action'],
    NewKeyword: ['search_term','amt_ad_14d','sum_spend','acos_14d','rule_name','modify_time','periods','reason', 'week_search_frequency_num', 'sum_impressions', 'ctr', 'cpc', 'cvr', 'cnt_ad_14d', 'sum_clicks', 'action'],
    NegativeNewKeyword: ['search_term', 'sum_spend', 'amt_ad_14d', 'acos_14d', 'rule_name', 'modify_time', 'reason', 'week_search_frequency_num', 'sum_impressions', 'ctr', 'cvr', 'cnt_ad_14d', 'sum_clicks', 'created_at', 'action'],
    BudgetList: ['campaign_name', 'sum_spend', 'amt_ad_14d', 'acos_14d', 'modify_time', 'rule_name', 'campaign_daily_budget', 'target_budget', 'reason', 'sum_impressions', 'ctr', 'cpc', 'cvr', 'cnt_ad_14d', 'sum_clicks', 'action'],
    AiBudgetList: ['campaign_name', 'sum_spend', 'amt_ad_14d', 'acos_14d', 'modify_time', 'rule_name', 'campaign_daily_budget', 'target_budget', 'reason', 'sum_impressions', 'ctr', 'cpc', 'cvr_14d', 'cnt_ad_14d', 'sum_clicks', 'action'],
    Found:['adtype','sum_spend','amt_ad_14d','units_sold_14d','acos_14d','ro_14d','sum_impressions','sum_clicks','cpc'],
  },
  isExpandFilter: false,
  openCreateRuleModal: false,
  appRuleFilter: {},
  portfolioFilter: {},
  campaignFilter: {},
  adGroupFilter: {},
  targetFilter: {},
  negativeTargetFilter: {},
  searchTermFilter: {},
  groupNegativeTargetFilter: {},
  groupTargetFilter: {},
  productFilter: {},
  ruleListMap: {
    'BID': [],
    'BUDGET': [],
    'HARVEST': [],
    'NEGATIVE': [],
  },
  reportUpdateTime:'',
};

/** assistantId */
export const getAssistantId = (state: RootState) => state.adtopic.assistantId || 'analysis';
/** 获取选中的类型 */
export const getSelectTab = (state: RootState) => state.adtopic.tab || new URLSearchParams(location.search).get('tab') || '';
/** 获取选中的店铺 shopAccountName */
export const getSelectShopAccountName = (state: RootState) => state.adtopic.selectShopAccountName || '';
/** 获取选中的国家 */
export const getSelectCountry = (state: RootState) => {
  const { selectShopAccountName = '' } = state.adtopic;
  const last_index = selectShopAccountName.lastIndexOf('_');
  if (last_index < 0) {
    return '' || 'US';
  }
  return selectShopAccountName.substring(last_index + 1) || 'US';
};
/** 获取选中的日期 */
export const getSelectDate = (state: RootState) => state.adtopic.selectDate;
/** 获取是否显示规则弹窗 */
export const getShowRuleModal = (state: RootState) => state.adtopic.showRuleModal;
/** 获取规则弹窗对应的type */
export const getRuleModalType = (state: RootState) => getShowRuleModal(state)?.type as RuleTypeEnum;
/** 获取数据加载状态 */
export const getDataLoading = (state: RootState) => state.adtopic.dataLoading;
/** 获取规则数据 */
export const getRuleDataSource = (state: RootState) => state.adtopic.ruleDataSource || [];
/** 获取分页数据 */
export const getPagination = (state: RootState) => state.adtopic.pagination;
/** 表格列 */
export const getColumns = (state: RootState) => state.adtopic.columns;
/** 是否展开过滤条件 */
export const getIsExpandFilter = (state: RootState) => state.adtopic.isExpandFilter;
/** 是否显示创建规则弹窗 */
export const getOpenCreateRuleModal = (state: RootState) => state.adtopic.openCreateRuleModal;
/** 获取应用规则过滤条件 */
export const getAppRuleFilter = (state: RootState) => state.adtopic.appRuleFilter;
/** 获取广告组合过滤条件 */
export const getPortfolioFilter = (state: RootState) => state.adtopic.portfolioFilter;
/** 获取广告活动过滤条件 */
export const getCampaignFilter = (state: RootState) => state.adtopic.campaignFilter;
/** 获取广告组过滤条件 */
export const getAdGroupFilter = (state: RootState) => state.adtopic.adGroupFilter;
/** 获取投放过滤条件 */
export const getTargetFilter = (state: RootState) => state.adtopic.targetFilter;
/** 获取否定投放过滤条件 */
export const getNegativeTargetFilter = (state: RootState) => state.adtopic.negativeTargetFilter;
/** 获取搜索词过滤条件 */
export const getSearchTermFilter = (state: RootState) => state.adtopic.searchTermFilter;
/** 获取产品过滤条件 */
export const getProductFilter = (state: RootState) => state.adtopic.productFilter;
/** 获取可选择的规则列表 */
export const getRuleListMap = (state: RootState) => state.adtopic.ruleListMap;
/** 获取AI高亮过滤条件 */
export const getAiHighlightedFilters = (state: RootState) => state.adtopic.aiHighlightedFilters;
/** 获取报表更新时间 */
export const getReportUpdateTime =  (state: RootState) => state.adtopic.reportUpdateTime;
/** 获取广告组详情广告否定投放过滤条件 */
export const getGroupNegativeTargetFilter = (state: RootState) => state.adtopic.groupNegativeTargetFilter;
/** 获取广告组详情广告投放过滤条件 */
export const getGroupTargetFilter = (state: RootState) => state.adtopic.groupTargetFilter;

export const getRuleData = createAsyncThunk('adtopic/getRuleData', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const pagination = getPagination(state);
  const ruleType = getSelectTab(state);
  if (ruleType === 'APP') {
    dispatch(getAppRuleData());
    dispatch(getRuleListByType('BID'));
    dispatch(getRuleListByType('BUDGET'));
    return;
  }
  const res = await getRuleDataList({
    page: pagination.current || 1,
    page_size: pagination.pageSize || 10,
    rule_types: [ruleType || 'BID']
  });

  const newPagination = {
    current: res.pagination?.page || 1,
    pageSize: res.pagination?.page_size || 10,
    total: res.pagination?.element_total || 0,
    showSizeChanger: true,
  };
  if (newPagination.current > res.pagination?.page_total && res.pagination?.page_total > 0) {
    newPagination.current = res.pagination?.page_total || 1;
    dispatch(updatePagination(newPagination));
    dispatch(getRuleData());
    return;
  }
  dispatch(updatePagination(newPagination));
  return res.ret;
});

export const getRuleListByType = createAsyncThunk('adtopic/getRuleListByType', async (ruleType: RuleTypeEnum, { dispatch }) => {
  const res = await getFilterData({
    distinct_column: 'RULE',
    is_all: true,
    rule_type: ruleType,
  });

  const list: RuleItem[] = res.ret;
  return {
    ruleType,
    list,
  };
});

export const getAppRuleData = createAsyncThunk('adtopic/getAppRuleData', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const pagination = getPagination(state);
  const {
    campaignIds, campaignName, campaignState, portfolioIds, adTypeTargetingType, automationBid
  } = getAppRuleFilter(state);
  const filter: GetAppRuleListParams = {
    page: pagination.current || 1,
    page_size: pagination.pageSize || 10,
    campaign_name_likes: campaignName ? [campaignName] : undefined,
    campaign_ids: campaignIds,
    campaign_state: campaignState,
    portfolio_ids: portfolioIds,
    ad_type_targeting_type: adTypeTargetingType?.map(item => adTypeTargetingTypeMap[item]),
    automation_bid: automationBid,
  };
  const res = await getAppRuleList(filter);

  const newPagination = {
    current: res.pagination?.page || 1,
    pageSize: res.pagination?.page_size || 10,
    total: res.pagination?.element_total || 0,
    showSizeChanger: true,
  };
  if (newPagination.current > res.pagination?.page_total  && res.pagination?.page_total > 0) {
    newPagination.current = res.pagination?.page_total || 1;
    dispatch(updatePagination(newPagination));
    dispatch(getAppRuleData());
    return;
  }
  dispatch(updatePagination(newPagination));
  return res.ret;
});


export const adtopicSlice = createSlice({
  name: 'adtopic',
  initialState,
  reducers: {
    updateAssistantId: (state, action: PayloadAction<string>) => {
      state.assistantId = action.payload;
    },
    updateSelectShopAccountName: (state, action: PayloadAction<string>) => {
      setLocalShopAccountName(action.payload);
      state.selectShopAccountName = action.payload;
    },
    updateSelectDate: (state, action: PayloadAction<[string, string]>) => {
      state.selectDate = action.payload;
    },
    updateShowRuleModal: (state, action: PayloadAction<ShowRuleModal | undefined>) => {
      state.showRuleModal = action.payload;
    },
    updateDataLoading: (state, action: PayloadAction<boolean>) => {
      state.dataLoading = action.payload;
    },
    updatePagination: (state, action: PayloadAction<PaginationProps>) => {
      state.pagination = action.payload;
    },
    updateColumns: (state, action: PayloadAction<PaginationProps>) => {
      state.columns = action.payload;
    },
    updateSelectTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    updateIsExpandFilter: (state, action: PayloadAction<boolean>) => {
      state.isExpandFilter = action.payload;
    },
    resetRuleDataSource: (state) => {
      state.ruleDataSource = [];
    },
    updateOpenCreateRuleModal: (state, action: PayloadAction<boolean>) => {
      state.openCreateRuleModal = action.payload;
    },
    updateAppRuleFilter: (state, action: PayloadAction<AppRuleFilterType | undefined>) => {
      if (!action.payload) {
        state.appRuleFilter = {};
        return;
      }
      state.appRuleFilter = {
        ...state.appRuleFilter,
        ...action.payload,
      };
    },
    updatePortfolioFilter: (state, action: PayloadAction<PortfolioFilterType | undefined>) => {
      if (!action.payload) {
        state.portfolioFilter = {};
        return;
      }
      state.portfolioFilter = {
        ...state.portfolioFilter,
        ...action.payload,
      };
    },
    setPortfolioFilter: (state, action: PayloadAction<PortfolioFilterType | undefined>) => {
      state.portfolioFilter = action.payload || {};
    },
    updateCampaignFilter: (state, action: PayloadAction<CampaignFilterType | undefined>) => {
      if (!action.payload) {
        state.campaignFilter = {};
        return;
      }
      state.campaignFilter = {
        ...state.campaignFilter,
        ...action.payload,
      };
    },
    updateAdGroupFilter: (state, action: PayloadAction<AdGroupFilterType | undefined>) => {
      if (!action.payload) {
        state.adGroupFilter = {};
        return;
      }
      state.adGroupFilter = {
        ...state.adGroupFilter,
        ...action.payload,
      };
    },
    updateTargetFilter: (state, action: PayloadAction<TargetFilterType | undefined>) => {
      if (!action.payload) {
        state.targetFilter = {};
        return;
      }
      state.targetFilter = {
        ...state.targetFilter,
        ...action.payload,
      };
    },
    updateNegativeTargetFilter: (state, action: PayloadAction<NegativeTargetFilterType | undefined>) => {
      if (!action.payload) {
        state.negativeTargetFilter = {};
        return;
      }
      state.negativeTargetFilter = {
        ...state.negativeTargetFilter,
        ...action.payload,
      };
    },
    updateSearchTermFilter: (state, action: PayloadAction<SearchTermFilterType | undefined>) => {
      if (!action.payload) {
        state.searchTermFilter = {};
        return;
      }
      state.searchTermFilter = {
        ...state.searchTermFilter,
        ...action.payload,
      };
    },
    updateProductFilter: (state, action: PayloadAction<ProductFilterType | undefined>) => {
      if (!action.payload) {
        state.productFilter = {};
        return;
      }
      state.productFilter = {
        ...state.productFilter,
        ...action.payload,
      };
    },
    setAiHighlightedFilters: (state, action: PayloadAction<string[] | undefined>) => {
      state.aiHighlightedFilters = action.payload;
    },
    updateReportUpdateTime: (state, action: PayloadAction<string>) => {
      state.reportUpdateTime = action.payload;
    },
    updateGroupNegativeTargetFilter: (state, action: PayloadAction<GroupNegativeTargetFilterType | undefined>) => {
      if (!action.payload) {
        state.groupNegativeTargetFilter = {};
        return;
      }
      state.groupNegativeTargetFilter = {
        ...state.groupNegativeTargetFilter,
        ...action.payload,
      };
    },
    updateGroupTargetFilter: (state, action: PayloadAction<GroupTargetFilterType | undefined>) => {
      if (!action.payload) {
        state.groupTargetFilter = {};
        return;
      }
      state.groupTargetFilter = {
        ...state.groupTargetFilter,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
    .addCase(resetModalAction, () => initialState)
    .addCase(getRuleData.pending, (state) => {
      state.dataLoading = true;
    })
    .addCase(getRuleData.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload) {
        return;
      }
      state.ruleDataSource = action.payload;
    })
    .addCase(getRuleData.rejected, (state) => {
      state.dataLoading = false;
    })
    .addCase(getAppRuleData.pending, (state) => {
      state.dataLoading = true;
    })
    .addCase(getAppRuleData.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload) {
        return;
      }
      state.ruleDataSource = action.payload;
    })
    .addCase(getAppRuleData.rejected, (state) => {
      state.dataLoading = false;
    })
    .addCase(getRuleListByType.fulfilled, (state, action) => {
      state.ruleListMap[action.payload.ruleType] = action.payload.list;
    })
  }
})

const resetModalAction = createAction('adtopic/reset-action');

export const {
  updateAssistantId,
  updateSelectShopAccountName,
  updateSelectDate,
  updateShowRuleModal,
  updateDataLoading,
  updatePagination,
  updateColumns,
  updateSelectTab,
  updateIsExpandFilter,
  resetRuleDataSource,
  updateOpenCreateRuleModal,
  updateAppRuleFilter,
  updatePortfolioFilter,
  setPortfolioFilter,
  updateCampaignFilter,
  updateAdGroupFilter,
  updateTargetFilter,
  updateNegativeTargetFilter,
  updateSearchTermFilter,
  updateProductFilter,
  setAiHighlightedFilters,
  updateReportUpdateTime,
  updateGroupNegativeTargetFilter,
  updateGroupTargetFilter,
} = adtopicSlice.actions;

export { resetModalAction };

export default adtopicSlice.reducer;
